<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ $t('elearning_tim.other_honorarium_sheet') }} {{ $t('globalTrans.search') }}
              </h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_memo_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_memo_no"
                            :options="circularList"
                            id="circular_memo_no"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_config.trainee_type')}}
                      </template>
                        <b-form-select
                          plain
                          v-model="search.other_trainee_type_id"
                          :options="traineeTypeList"
                          id="other_trainee_type_id"
                          >
                          <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.other_honorarium_sheet') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                      <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(personal_info_id)="data">
                                      {{ ($i18n.locale==='bn') ? data.item.personal.name_bn : data.item.personal.name }}
                                    </template>
                                    <template v-slot:cell(other_trainee_type)="data">
                                      <span class="capitalize">{{ getOtherTraineeTypeName(data.item.other_trainee_type_id) }}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <router-link :to="`other-honorarium-sheet-details?circilarMemoNo=${data.item.circular_memo_no}&payment_type=Trainer&otherTraineeType=${data.item.other_trainee_type_id}`" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                    </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <!-- <b-modal id="modal-5" size="lg" :title="$t('elearning_venue.make_payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Payment :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tim.other_honorarium_amount')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Attachment :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentHonorarumListOther, billGeneratePaymentUpdate, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Payment from './Payment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    //   Details
    },
    data () {
        return {
          circularLoading: false,
          search: {
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            other_trainee_type_id: 0,
            circular_memo_no: 0,
            training_status: 2,
            payment_type: 'Trainer',
            fiscal_year_id: 0
          },
          circularList: [],
          trainingTypeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          officeTypeList: [],
          allBatchListData: [],
          otherTraineeTypeList: [],
          traineeTypeList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      // trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_tim.other_honorarium_amount') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_tim.other_honorarium_amount') + ' ' + this.$t('globalTrans.modify')
      },
       columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_config.other_trainee_type'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'circular_memo_no' },
              { key: 'other_trainee_type' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'org' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'other_trainee_type' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.search.org_id = this.$store.state.Auth.authUser.org_id
          this.search.office_type_id = this.$store.state.Auth.authUser.office_type_id
          this.search.office_id = this.$store.state.Auth.authUser.office_id
        }
        this.paymentStatusUpdate()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.org_id': function (newValue, oldValue) {
        this.trainingTypeList = this.getTypeList()
        if (newValue !== oldValue) {
          if (this.$store.state.Auth.activeRoleId === 1) {
            this.officeTypeList = this.getOfficeTypeList(newValue)
          }
        }
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getCircularList()
        }
      }
      // 'search.circular_memo_no': function (newValue) {
      //   this.getOtherTraineeType()
      // }
    },
    methods: {
        getOfficeTypeList (orgId) {
          const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
          if (orgId) {
            return OfficeTypeList.filter(item => item.org_id === orgId)
          }
          return OfficeTypeList
        },
        getOfficeList (officeTypeId) {
          const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
          if (officeTypeId) {
            return officeList.filter(item => item.office_type_id === officeTypeId)
          }
          return officeList
        },
        getOtherTraineeTypeName (id) {
          const otherTraineeType = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.filter(item => item.status === 1).find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return otherTraineeType !== undefined ? otherTraineeType.text_bn : ''
          } else {
              return otherTraineeType !== undefined ? otherTraineeType.text_en : ''
          }
        },
        async getOtherTraineeType () {
        this.circularLoading = true
        const serchData = {
          org_id: this.search.org_id,
          fiscal_year_id: this.search.fiscal_year_id,
          office_id: this.search.office_id,
          office_type_id: this.search.office_type_id,
          circular_memo_no: this.search.circular_memo_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.otherTraineeTypeList = []
        } else {
          this.otherTraineeTypeList = result.otherTraineeTypeList
          this.getTraineeType()
        }
        this.circularLoading = false
      },
      getTraineeType () {
        const typeData = this.otherTraineeTypeList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.other_trainee_type_bn }
          } else {
            return { value: obj.id, text: obj.other_trainee_type }
          }
        })
        if (typeData) {
          this.traineeTypeList = typeData
        } else {
          this.traineeTypeList = []
        }
      },
      async getCircularList () {
        if (this.search.fiscal_year_id && this.search.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.search.fiscal_year_id,
            training_type_id: this.search.training_type_id,
            training_category_id: this.search.training_category_id,
            training_title_id: this.search.training_title_id,
            office_id: this.search.office_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
            this.allBatchListData = []
          } else {
            this.allBatchListData = result.batchList
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      getTypeList () {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (this.search.org_id) {
            return type.filter(item => item.org_id === parseInt(this.search.org_id))
          }
          return type
        }
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumListOther, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const divisionList = this.$store.state.commonObj.divisionList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const unionList = this.$store.state.commonObj.unionList
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
          const districtObject = districtList.find(district => district.value === item.pre_district_id)
          const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
          const unionObject = unionList.find(union => union.value === item.pre_union_id)
          const divisionData = {
              division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
              division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
            district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
            upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
          }
          const unionData = {
            union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
            union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
          }
          const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
          const addressData = {
            address: address
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, addressData)
        })
        return listData
      },
      async paymentStatusUpdate () {
        if (this.$route.params.status && this.$route.query.trnsId) {
          const status = this.$route.params.status
          const trnsId = this.$route.query.trnsId
          await RestApi.getData(trainingElearningServiceBaseUrl, billGeneratePaymentUpdate + status, { trnsId: trnsId }).then(response => {
            if (response.success) {
              this.$toast.success({
                title: 'Success',
                message: response.message,
                color: '#D6E09B'
              })
            } else {
              this.$toast.error({
                title: 'Error',
                message: response.message
              })
            }
            this.loadData()
            this.$router.push('/training-e-learning-service/tim/bill-payment')
          })
        } else {
          this.loadData()
        }
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
